import React from 'react';

import './ServiceItem.scss';

export default function ServiceItem(props) {
  return (
    <div className="ServiceItem">
      <div className="item-row">
        <div className="icon-container">
          <img src={props.icon} alt={props.title} />
        </div>
        <div className="text-area">
          <div className="title">
            {props.title}
          </div>
          <div className="body gotham-light">
            {props.body}
          </div>
        </div>
      </div>
    </div>
  );
}
