import React from 'react';

import './About.scss';

export default function About() {
  return (
    <div className="About">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12 page-heading">
            <p className="gotham-medium l1">ABOUT US</p>
          </div>
          <div className="col-md-6 col-sm-12 text">
            <p className="l1 gotham-medium">
              F&P Trading Ltd. is a Swiss company with a purely Swiss shareholder base. The company specializes in distributing medical supplies worldwide.
            </p>
            <p className="l2">
              Due to COVID-19, protective masks, protective clothing and disinfectants have become scarce worldwide.
            </p>
            <p className="l3">
              In view of the dramatic development of the international pandemic and as a contribution to the fight against the COVID-19 pandemic, F&P Trading has decided to work with internationally known medical companies to supply the world with protective masks, protective suits, nitrile and vinyl gloves, test kits, goggles as well as disposable shoe protection and hand sanitizer.
            </p>
            <p className="l4">
              The health of our clients is close to our heart. That is why F&P’s team of experienced sourcing and trading experts places great emphasis on sourcing only safe and high-quality goods and offering them at competitive prices.
            </p>
            <p className="l5">
              All of our medical supplies we offer are certified and of reliable quality. We offer a comprehensive service including transport (CIF/DDP) to your doorstep, wherever you are.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}