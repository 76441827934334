import React from 'react';

import './SectionHeader.scss';

export default function SectionHeader(props) {
  return (
    <div className="SectionHeader">
      <div className="title">
        {props.children}
      </div>
      <div className="bar" />
    </div>
  );
}