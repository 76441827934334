import React from 'react';

import './PrivacyPolicy.scss';

export default function PrivacyPolicy() {
  return (
    <div className="PrivacyPolicy container">

      <h1>PRIVACY POLICY</h1>
      <p>
        Responsible person within the meaning of the data protection laws, in particular the EU General Data Protection Regulation (GDPR):<br />
        F&P Trading AG <br />
        Stefan Bachmann <br />
        Telefon: +41 41 768 1101 <br />
        E-Mail: <a href="mailto:sba@fptrading.ch">sba@fptrading.ch<br />
        </a>Website: <a href="www.fptrading.ch">www.fptrading.ch</a>
      </p>

      <h2>GENERAL</h2>
      <p>We take the protection of your personal data privacy very seriously. We handle your personal data in complete confidence and strictly in accordance with statutory data protection regulations as well as this privacy policy.</p>
      <p>By using this website, you agree to the collection, processing and use of data as described below. This website is accessible without registration. Data such as viewed pages or names of files accessed, date and time are stored on our server purely for statistical purposes, without it being related directly to you. We do not collect personal data and no data is passed on to third parties.</p>

      <h2>COOKIES</h2>
      <p>This website does not use any cookies.</p>

      <h2>SERVER LOG FILES</h2>
      <p>The website collects and stores information in so-called server log files which your browser automatically transmits, such as:</p>
      <ul>
        <li>Browser type and version</li>
        <li>The operating system being used</li>
        <li>Referrer URL</li>
        <li>Host name of the accessing computer</li>
        <li>Time of the server request</li>
      </ul>
      <p>This data is not attributable to specific persons nor merged with other data sources. We reserve the right to check this data retrospectively, should there be specific indications of illegal use.</p>

      <h2>CHANGES TO PRIVACY POLICY</h2>
      <p>We reserve the right to make changes to this privacy policy at any point without prior notice. The current version as published on the website applies.</p>

      <h2>QUESTIONS?</h2>
      <p>For any questions on this privacy policy contact <a href="mailto:sba@fptrading.ch">sba@fptrading.ch</a></p>
      <p>Baar, May 2020</p>

    </div>
  );
}
