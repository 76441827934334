import React from 'react';

import './MainPage.scss';

// Pages
import Home from './home/Home';
import Production from './production/Production';
import Service from './service/Service';
import About from './about/About';
import Contact from './contact/Contact';

export default function MainPage() {
  return (
    <div className="MainPage">
      <Home />
      <About />
      <Production />
      <Service />
      <Contact />
    </div>
  );
}
