import React from 'react';
import { Animated } from "react-animated-css";

import './Home.scss';

export default function Home() {
  return (
    <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={100}>
      <div className="Home">

        <div className="home-container container">
          <Animated animationIn="slideInDown" animationOut="fadeOut" isVisible={true} animationInDelay={500}>
            <div className="logo">
              <img src="/assets/images/logo.png" alt="F&P-logo" />
            </div>
          </Animated>
          <Animated animationIn="slideInUp" animationOut="fadeOut" isVisible={true} animationInDelay={600}>
            <div className="text">
              <p className="l1">MEDICAL SUPPLIES</p>
              <p className="l2">IN CHALLENGING TIMES</p>
            </div>
          </Animated>
        </div>

      </div>
    </Animated>
  );
}