import React from 'react';

import './Production.scss';

export default function Production() {
  return (
    <div className="Production">
      <div className="container production-container">
        <div className="text">
          <img src="/assets/images/production.png" alt="production" />
        </div>
      </div>
    </div>
  );
}