import React from 'react';
import { Link } from 'react-router-dom';

import SectionHeader from '../../../shared/section-header/SectionHeader';

import './Contact.scss';


export default function Contact() {
  return (
    <div className="Contact" id="contact">
      <div className="container">
        <SectionHeader>
          CONTACT
        </SectionHeader>

        <div className="body">
          <div className="title">
            F&P Trading Ltd.
          </div>
          <div className="address">
            Grabenstrasse 25 &nbsp; • &nbsp; 6340 Baar &nbsp; • &nbsp; Switzerland
          </div>
          <div className="address">
            T +41 41 768 11 01 &nbsp; • &nbsp;  M +41 79 279 08 80
          </div>
          <div className="address">
            <a href="mailto:sba@fptrading.ch" target="_blank" rel="noopener noreferrer">sba@fptrading.ch</a> &nbsp; • &nbsp; <a href="http://www.fptrading.ch/">www.fptrading.ch</a>
          </div>
          <div className="row bottom-link">
            <span className="col-md-6 col-sm-12">2020 ©by F&P Trading AG. All rights reserved.</span>
            <span><Link to="/privacy_policy" target="_blank" rel="noopener noreferrer">Privacy Policy</Link></span>
          </div>
        </div>

      </div>
    </div>
  );
}