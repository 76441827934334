import React from 'react';

import './Service.scss';

import ServiceItem from './service-item/ServiceItem';

export default function Service() {
  return (
    <div className="Service">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12 page-heading">
            <p className="gotham-medium l1">OUR SERVICES</p>
          </div>
          <div className="col-md-6 col-sm-12 service-list-area">
            <div className="sub-heading">
              We offer medical supplies combined with logistical services tailored to your requirements (EXW, FOB or CIF)
            </div>
            <div className="service-items">
              <ServiceItem
                icon='/assets/images/icons/our-service-1.png'
                title='Product Effectiveness'
                body='The masks we sell have a multi-layer structure that can filter particles as small as 0.3 micrometers.'
              />
              <ServiceItem
                icon='/assets/images/icons/our-service-2.png'
                title='Certification'
                body='Our products are manufactured under strict ISO regulations, meet relevant international performance standards such as FFP2, FFP3 or N95/KN95 and have FDA and CE certifications.'
              />
              <ServiceItem
                icon='/assets/images/icons/our-service-3.png'
                title='Quality Control'
                body='We focus on providing excellent service from product quality control to delivery checks.'
              />
              <ServiceItem
                icon='/assets/images/icons/our-service-4.png'
                title='Shipping'
                body='We work together with leading forwarding companies and guarantee efficient and reliable delivery worldwide.'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
